import React, { useEffect } from 'react';

const ContactUs: React.FC = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h2 className="text-5xl font-bold mt-4 text-center font-serif font-light">Contact Us</h2>
      <img src="/images/Bookings_image.jpg" alt="Casa Romantica Villa" className="mx-auto my-4" />
      <p className="mt-4">Thank you for visiting our website for Casa Romantica in Guia, Central Algarve.</p>
      <p className="mt-4">For enquiries and further information about booking please contact us by phone or e-mail.</p>
      <p className="mt-4">
        <strong>Telephone</strong>: <a href="tel:+447733325334" className="text-blue-500 hover:underline">+44 7733 325334</a>
      </p>
      <p className="mt-4">
        <strong>Email</strong>: <a href="mailto:enquiries@algarve-villa-holidays.net" className="text-blue-500 hover:underline">enquiries@algarve-villa-holidays.net</a>
      </p>
    </div>
  );
};

export default ContactUs;
