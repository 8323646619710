import React, { useState } from "react";
import { NavLink } from "react-router-dom";

// Sample photos array
const photos = [
  { id: 1, src: "/images/evening_villa_front.jpeg", alt: "Evening Villa Front" },
  { id: 2, src: "/images/lunch_dining.jpg", alt: "Dining Daytime" },
  { id: 3, src: "/images/terrace.jpg", alt: "Additional Terrace" },
  { id: 4, src: "/images/sunset_beach.jpg", alt: "Local Beach Sunset" },
];

const Home: React.FC = () => {
  const [showSlideshow, setShowSlideshow] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState<number | null>(null);

  const handlePhotoClick = (photoId: number) => {
    setCurrentPhoto(photoId);
    setShowSlideshow(true);
  };

  const handleCloseSlideshow = () => {
    setShowSlideshow(false);
    setCurrentPhoto(null);
  };

  return (
    <div>
      <h2 className="text-5xl font-bold mt-4 text-center font-serif font-light">Discover Casa Romantica</h2>
      <img src="heart.svg" className="mx-auto mt-0 h-12 w-12" />
      <p className="mt-1 text-lg">
        Casa Romantica is a beautiful private holiday villa and swimming pool set in its own private walled grounds. It
        is in perfect destination for family holidays in the Algarve as it is within a close distance to family friendly
        restaurants, beaches, water parks, mini-markets and the old town of Albufeira. This luxury villa in Guia offers
        great private villa rental algarve accommodation and is 30 mins from Faro airport, either by hiring a car or
        pre-booking a taxi from the airport.
      </p>
      {!showSlideshow ? (
        <div className="cursor-pointer">
          <img
            src={"/images/homepage.jpg"}
            alt={"test"}
            className="w-full h-auto object-cover rounded shadow"
            onClick={() => handlePhotoClick(1)}
          />
        </div>
      ) : (
        <Slideshow photos={photos} currentPhotoId={currentPhoto} onClose={handleCloseSlideshow} />
      )}

      {/* Flex container for image and bullet points */}
      <div className="flex flex-wrap items-start mt-6">
        {/* Image Section */}
        <div className="w-full md:w-auto mt-0 md:mr-8">
          <img
            src="/images/Casa_Romantica_Tile.jpg"
            alt="Accommodation"
            className="w-96 h-96 object-contain rounded"
          />
        </div>

        {/* Text Section */}
        <div className="flex-1">
          <ul className="list-disc pl-8 text-gray-800 text-left space-y-2">
            <li>Sleeps 6-8</li>
            <li>Free Wi-Fi</li>
            <li>Private heated swimming pool</li>
            <li>1 Double bedroom (Superking bed, ensuite bathroom & air-con)</li>
            <li>2 twin bedrooms with air-conditioning</li>
            <li>Family bathroom with shower & shower room</li>
            <li>5 mins walk to restaurants and shops</li>
            <li>Welcome pack upon arrival</li>
            <li>Single-storey villa</li>
            <li>Roof sun terrace with great views</li>
            <li>Air conditioning in all bedrooms, living room, kitchen and playroom</li>
            <li>5 mins drive (or taxi) to water parks or beaches</li>
            <li>Private BBQ, bread/pizza oven and outdoor dining areas</li>
            <li>Sofabed & free additional beds available</li>
            <li>All bedrooms have built-in wardrobes</li>
            <li>Hundreds of TV channels (including sports channels)</li>
            <li>Netflix, Amazon Prime, Disney Plus</li>
            <li>Cots, push chairs and high chairs are free</li>
            <li>Cleaning Service 3 times a per week</li>
            <li>Close to golf courses and discounts available</li>
            <li>No pets allowed</li>
            <li>No smoking inside the villa</li>
            <li>Children's playroom with Xbox 360, Sony PS2 and toys</li>
            <li>The villa has a safe for personal possessions</li>
            <li>Close to water parks</li>
          </ul>
        </div>
      </div>

      {/* Ensure additional text is separate */}
      <div className="mt-8">
        <p className="text-lg">
          Guia's village life is quiet and peaceful with local mini-markets, good value restaurants and bars all within
          walking distance of the villa. Whilst the main Algarve Shopping Centre with its bowling alleys, cinemas,
          shops and restaurants are only a few minutes drive away.
        </p>
        <p className="text-lg mt-4">
          This family villa in Algarve offers bright and spacious family accommodation, set amidst some beautiful
          scenery and among other white-washed villas in Guia.
        </p>
        {/* Include additional text */}
        <p className="text-lg mt-4">
          We publish our prices and {' '}
                  <NavLink to="/ContactUs" className="text-blue-500 hover:text-blue-700">
                    making a booking
                  </NavLink>{' '}is very easy. For more information about planning your private
          villa rental Algarve holiday in Guia then go to our{" "}
          <NavLink to="/Pricing" className="text-blue-500 hover:text-blue-700">
            Pricing
          </NavLink>{" "}
          page for more details.
        </p>
      </div>
    </div>
  );
};

// Slideshow Component
interface SlideshowProps {
  photos: { id: number; src: string; alt: string }[];
  currentPhotoId: number | null;
  onClose: () => void;
}

const Slideshow: React.FC<SlideshowProps> = ({ photos, currentPhotoId, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(
    photos.findIndex((photo) => photo.id === currentPhotoId)
  );

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center z-50">
      {/* Exit button */}
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-white text-2xl bg-gray-600 px-4 py-2 rounded hover:bg-red-700"
      >
        X
      </button>

      {/* Slideshow navigation */}
      <div className="text-center flex items-center">
        <button onClick={handlePrevious} className="text-white text-3xl mr-4">
          &lt;
        </button>
        <img
          src={photos[currentIndex].src}
          alt={photos[currentIndex].alt}
          className="max-w-full max-h-[80vh] object-contain rounded"
        />
        <button onClick={handleNext} className="text-white text-3xl ml-4">
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Home;

