import React, { useState } from 'react';

// PhotoGallery Component
const PhotoGallery: React.FC = () => {
  const photos = [
    { id: 1, src: '/images/evening_villa_front.jpeg', alt: 'Evening Villa Front' },
    { id: 2, src: '/images/lunch_dining.jpg', alt: 'Dining Daytime' },
    { id: 3, src: '/images/terrace.jpg', alt: 'Additional Terrace' },
    { id: 4, src: '/images/sunset_beach.jpg', alt: 'Local Beach Sunset' },
    { id: 4, src: '/images/master_bedroom1.jpeg', alt: 'Local Beach Sunset' },
    { id: 4, src: '/images/master_bedroom2.jpeg', alt: 'Local Beach Sunset' },
    { id: 4, src: '/images/master_bedroom3.jpg', alt: 'Local Beach Sunset' },
    { id: 4, src: '/images/master_bathroom.jpeg', alt: 'Local Beach Sunset' },
    { id: 4, src: '/images/terrace_plant.jpg', alt: 'Local Beach Sunset' },
    { id: 4, src: '/images/blue_bathroom.jpeg', alt: 'Local Beach Sunset' },
    { id: 4, src: '/images/kitchen.jpeg', alt: 'Local Beach Sunset' },
    { id: 4, src: '/images/lounge1.jpeg', alt: 'Local Beach Sunset' },
    { id: 4, src: '/images/lounge2.jpg', alt: 'Local Beach Sunset' },
    { id: 4, src: '/images/daytime_villa_front.jpeg', alt: 'Local Beach Sunset' },
    { id: 4, src: '/images/blue_bedroom1.jpeg', alt: 'Local Beach Sunset' },
    { id: 4, src: '/images/pink_bathroom.jpeg', alt: 'Local Beach Sunset' },
    { id: 4, src: '/images/yellow_bedroom1.jpeg', alt: 'Local Beach Sunset' },
    { id: 4, src: '/images/bbq.jpeg', alt: 'Local Beach Sunset' },
    { id: 4, src: '/images/terrace_3.jpeg', alt: 'Local Beach Sunset' },
    { id: 4, src: '/images/rooftop.jpeg', alt: 'Local Beach Sunset' },
  ];

  const [showSlideshow, setShowSlideshow] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState<number | null>(null);

  const handlePhotoClick = (photoId: number) => {
    setCurrentPhoto(photoId);
    setShowSlideshow(true);
  };

  const handleCloseSlideshow = () => {
    setShowSlideshow(false);
    setCurrentPhoto(null);
  };

  return (
    <div className="max-w-4xl mx-auto my-8 px-4">
      <h2 className="text-5xl font-bold mt-4 text-center font-serif font-light ">Photo Gallery</h2>
      <img src="heart.svg" className="mx-auto mt-0 h-12 w-12"/>
      <p className="mt-4 mb-6">Click on the images below to open a slideshow and browse through photos of the villa and surrounding areas.</p>
      
      {/* Image Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
        {photos.map((photo) => (
          <img
            key={photo.id}
            src={photo.src}
            alt={photo.alt}
            className="cursor-pointer rounded shadow hover:shadow-lg transition"
            onClick={() => handlePhotoClick(photo.id)}
          />
        ))}
      </div>
      
      {/* Slideshow */}
      {showSlideshow && currentPhoto !== null && (
        <Slideshow
          photos={photos}
          currentPhotoId={currentPhoto}
          onClose={handleCloseSlideshow}
        />
      )}
    </div>
  );
};

// Slideshow Component
interface SlideshowProps {
  photos: { id: number; src: string; alt: string }[];
  currentPhotoId: number;
  onClose: () => void;
}

const Slideshow: React.FC<SlideshowProps> = ({ photos, currentPhotoId, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(
    photos.findIndex((photo) => photo.id === currentPhotoId)
  );

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center z-50">
      {/* Exit button */}
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-white text-2xl bg-gray-600 px-4 py-2 rounded hover:bg-red-700"
      >
        X
      </button>

      {/* Slideshow navigation */}
      <div className="text-center flex items-center">
        <button
          onClick={handlePrevious}
          className="text-white text-3xl mr-4 hover:text-gray-300"
        >
          &lt;
        </button>
        <img
          src={photos[currentIndex].src}
          alt={photos[currentIndex].alt}
          className="max-w-full max-h-[80vh] object-contain rounded"
        />
        <button
          onClick={handleNext}
          className="text-white text-3xl ml-4 hover:text-gray-300"
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default PhotoGallery;
