import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const About: React.FC = () => {
  const [visitCount, setVisitCount] = useState<number>(0);

  useEffect(() => {
    // Check if the browser environment is available
    if (typeof window !== 'undefined') {
      // Retrieve the visit count from local storage (if available)
      const storedCount = localStorage.getItem('visitCount');
      const currentCount = storedCount ? parseInt(storedCount, 10) : 0;

      // Increment the visit count
      const newCount = currentCount + 1;
      setVisitCount(newCount);

      // Update local storage with the new count
      localStorage.setItem('visitCount', newCount.toString());
    }
  }, []);

  return (
    <div>
      <h1 className="text-5xl font-bold mt-4 text-center font-serif font-light">About Us</h1>
      <img src="heart.svg" alt="Heart icon" className="mx-auto mt-0 h-12 w-12" />
      <h2 className="text-xl font-bold">Family villa in the Algarve region of Portugal for holiday rentals</h2>
      <p className="mt-4">
        Our family welcomes new and old guests to view our privately owned luxury holiday villa with pool in the Algarve
        region of Portugal. Casa Romantica is located in the beautiful Portuguese village of Guia near Albufeira, which
        is 30 minutes from Faro airport. This beautiful villa is available to rent all year round and is great for
        family holidays and Algarve golf holidays. For many years we have enjoyed spending our family villa holidays in
        the traditional Portuguese village of Guia, near Albufeira on the Central Algarve coastline.
      </p>
      <img src="/images/About_us.jpg" alt="Casa Romantica Villa" className="mx-auto my-4" />
      <h2 className="text-xl font-bold">Portugal Algarve private villa holidays</h2>
      <p className="mt-4">
        Casa Romantica combines the traditional Portuguese style with modern comforts of a luxury villa with private pool
        and large terraces. Our family villa in Algarve is situated in a quiet residential area only a few minutes' walk
        from Guia's shops, restaurants, and bars. The flexibility of a villa is great - you choose what to do and when.
        Don't let a hotel holiday itinerary get in the way of your relaxing holiday in the sun. Get the best{' '}
        <NavLink to="/Pricing" className="text-blue-500 hover:text-blue-700">
          prices
        </NavLink>{' '}
        and {' '}
        <NavLink to="/ContactUs" className="text-blue-500 hover:text-blue-700">
          deal directly with the villa owners
        </NavLink>{'.'} Having spent our own Algarve villa holidays in Portugal in the beautiful
        fishing villages along the Algarve coastline, we have experienced a great deal of the relaxed Portuguese way of
        life, fantastic country, and friendly people.
      </p>
      <h2 className="text-xl font-bold">Private villa rental Algarve</h2>
      <p className="mt-4">
        Casa Romantica is priced for exclusive occupancy, not per person, and will accommodate up to 8 guests. It is in a
        perfect location for family groups and friends wishing to relax by the pool, go to the beach, sightsee, or
        partake in some outdoor activities like water sports, golf, walking, or horse riding. Alternatively, just relax
        and enjoy the wonderful all-year-round Algarve climate, beautiful clean beaches, breathtaking sea views, and
        superb local cuisine.
      </p>
      <p className="mt-4">
        Our aim is to make sure your stay in Portugal is as relaxing and enjoyable as possible. We can provide all
        strollers, high-chairs, and cots for babies free of charge. We can also help you with the best-priced flights and
        airport transfers. Upon arrival at Casa Romantica, there will be a {' '}
        <NavLink to="/FAQs" className="text-blue-500 hover:text-blue-700">
          welcome pack of essentials
        </NavLink>{'.'}
      </p>
      <p className="mt-4">Best Wishes,</p>
      <p className="mt-4">Steve and the Sanders family.</p>
    </div>
  );
};

export default About;
