import React from 'react';

type Review = {
  stars: number; // Number of stars (1-5)
  review: string; // The review text
  date: string; // The date the review was given
  source: string; // Source of the review
  name: string; // Name of the reviewer
};

// Mock data for reviews
const reviews: Review[] = [
  { stars: 5, review: "Amazing and spotless Clean Villa with a great private pool in a beautiful location. Everything you need for a great holiday is there in the villa and suited all ages. The owner was so helpful to deal with and sent a fantastic information pack to us before we left for our holiday which was extremely helpful. Couldn’t recommend a stay in this villa highly enough.", date: "2024-08-21", source: "TripAdvisor", name: "Joanne M, UK" },
  { stars: 5, review: "My wife and I had a fantastic 6 days at Steve and Jackie's lovely villa in Guia, it is in a very quiet and peaceful area and just the job for a chillaxing break. The villa is very well equipped with comfy beds, great BBQ and a large heatable pool. The owners left very comprehensive guides to the local area and the Algarve in general, the local restaurants we visited were top notch. We have rented dozens of Villas in Portugal over the years and Casa Romantica ranks easily in the top 5. The Villa is outstanding and we will certainly visit again later this year if there is availability.", date: "2024-04-17", source: "TripAdvisor", name: "Mike D, UK" },
  { stars: 5, review: "The villa is beautiful- spacious and well equipped. The location is amazing with local shops, a great range of restaurants and beautiful beaches on the doorstep. We had the pool heated; it was the perfect temperature to swim in. The owners were really well organised and on hand to answer any questions we had. We really hope to come back! Thank you!", date: "2022-04-20", source: "Let's Let Algarve", name: "Sarah J, UK" },
  { stars: 5, review: "Amazing! Beautiful villa so quiet and relaxing and spotless everything you need for a great stay .stayed here at Christmas was loverly the heated pool was great . Steve and Jackie helped us so much we thank them for their help . Love this place hope to come back very soon to Portugal lovely place and great local people .", date: "2021-12-09", source: "Let's Let Algarve", name: "Dave H, UK" },
  { stars: 5, review: "The pictures of the house and surrounds are good ...however the property in real life is 10 times better than the pictures. We were a family of 5, the house was so spacious with everything you could require. The pool is a lot bigger than we had expected - we spent every day - all day by the pool - with plenty of sun loungers that we didn't even have to move around to catch the sun as there were spare ones. Night time swims were enjoyed by the girls, while we watched them & the amazing sunsets in the area. Very close to the village that has everything you need. Some great value restaurants & yes the peri - peri chicken is a must! Located close enough to the airport for driving or a transfer - within easy reach of other areas of the algarve & some great shopping. But really you need never move too far from this house as it has it all ! We will definitely be back to this property - the owners were great at corresponding with us prior to the trip and sent us all the information and more before we arrived. A fabulous stress free trip from start to finish - thank you so much from all of us.", date: "2019-08-10", source: "Let's Let Algarve", name: "John S, UK" },
  { stars: 5, review: "Hi Steve, We just wanted to thank you for letting us stay in your amazing home in Portugal. We had the most fantastic holiday and Casa Romantica was the perfect base. It's a beautiful villa with everything we could have wanted. The pool was a huge hit with our daughters who spent nearly every hour of every day in it, and having it heated meant it was an ideal temperature for us all. We enjoyed exploring the area and your information guides were extremely helpful. We very much hope that we can stay again in the future and will of course be in touch when we get round to thinking about our next holiday. Thanks for everything Steve. You helped us have a most memorable first family holiday in the sun.", date: "2012-05-10", source: "Booked Directly", name: "Ben and Kate McAuley, UK" },
  { stars: 5, review: "This was our first time staying in Casa Romantica and I would have no problem recommending the villa. The property is in excellent condition, is very spacious and comfortably slept 4 adults and 3 children. Its very well equipped and everyting you could need is there. The pool area was secure and its overall proximity to the house was great for bringing out drinks etc. The line in the pool to show the shallow end was also great. The location is perfect - just far enough out the village for piece and quiet but close enough to walk to the restaurants and bars. The shopping center is also only a couple of minutes drive. The booking process was smooth and we were provided with all the information we would need for our stay prior to departure.", date: "2017-09-20", source: "Simply Owners", name: "Jon C, UK" },
  { stars: 5, review: "Wonderful villa ideal for a family holiday. Well equipped, spacious and clean. Peaceful location and short drive from loads of shops in two shopping centres as well as walking distance from local village. The pool was great, large enough for older children to swim but with shallow end for smaller ones. The garden was generously sized with plenty of room to eat, relax and play around pool. Pool area was safe as lockable gates made sure children didn't sneak out into water. Also we enjoyed eating breakfast on the patio outside the kitchen, and watching stars come out on the roof terrace. Beaches locally are a short drive away, clean and beautiful. There are things to do like zoo and water parks nearby, but we mostly just stayed in the villa as it was so nice. The children have asked regularly can we go back! Heartily recommended.", date: "2016-03-10", source: "Simply Owners", name: "Katherine W, UK" },
  { stars: 5, review: "We had our family holiday there and it was excellent. The villa is immaculate and the facilities are brilliant. The heated pool, wi-fi and skytv kept us nice and busy and the kids never wanted to leave the place ! Close to town and good shops, golf nearby and not far from Galle beach and Albeufuira but far enough out to be private and restful. We will go again ! Thanks from Dave and the family.", date: "2016-04-12", source: "Simply Owners", name: "David G, UK" },


];

const Guestbook: React.FC = () => {
  return (
    <div className="p-4">
      {/* Header */}
      <h1 className="text-5xl font-bold mt-4 text-center font-serif font-light">Guestbook</h1>
      <img src="heart.svg" alt="Heart Icon" className="mx-auto mt-0 h-12 w-12" />
      <img src="/images/banner2.jpg"/>
      <p className="mt-4 text-center">
        Welcome to Casa Romantica's Guestbook! Here you can read reviews from our clients over the last few years.
      </p>
      
      {/* Reviews Section */}
      <div className="mt-8 max-w-4xl mx-auto">
        <h2 className="text-2xl font-bold mb-4">Client Reviews</h2>
        <div className="overflow-y-auto max-h-96 border border-gray-200 rounded-lg shadow-md p-4">
          {reviews.map((review, index) => (
            <div
              key={index}
              className="mb-4 p-4 border-b border-gray-300 last:border-b-0"
            >
              {/* Stars */}
              <div className="flex items-center mb-2">
                {Array.from({ length: review.stars }, (_, i) => (
                  <span key={i} className="text-yellow-500">&#9733;</span> // Filled star
                ))}
                {Array.from({ length: 5 - review.stars }, (_, i) => (
                  <span key={i} className="text-gray-300">&#9733;</span> // Empty star
                ))}
              </div>
              {/* Reviewer Name */}
              <p className="text-lg font-semibold text-gray-800 mb-1">{review.name}</p>
              {/* Review Text */}
              <p className="text-lg text-gray-800 mb-1">{review.review}</p>
              {/* Source and Date */}
              <p className="text-sm text-gray-500">
                Reviewed on {review.date} | Source: {review.source}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Guestbook;
