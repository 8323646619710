import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

type TopBarProps = {
  setActivePage: (page: string) => void; // Optional, if you want to keep track of the active page
};

const TopBar: React.FC<TopBarProps> = ({ setActivePage }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="w-full bg-villablue text-white p-4 flex items-center justify-between h-16 fixed top-0 left-0 z-10">
      {/* Logo */}
      <h2 className="text-xl font-bold mr-4">Casa Romantica</h2>

      {/* Navigation (buttons aligned to the left) */}
      <div className="flex-grow">
        <ul
          className={`flex flex-col md:flex-row md:items-center md:space-x-8 ${
            isMobileMenuOpen
              ? 'absolute top-16 left-0 w-full bg-villablue p-4 space-y-4 md:space-y-0'
              : 'hidden'
          } md:flex`}
        >
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? 'text-gray-300' : 'text-base hover:text-gray-300'
              }
              onClick={() => setActivePage('home')}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive ? 'text-gray-300' : 'text-base hover:text-gray-300'
              }
              onClick={() => setActivePage('about')}
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/calendar"
              className={({ isActive }) =>
                isActive ? 'text-gray-300' : 'text-base hover:text-gray-300'
              }
              onClick={() => setActivePage('calendar')}
            >
              Calendar
            </NavLink>
          </li>
          <li className="relative">
            {/* Dropdown trigger */}
            <button
              onClick={() => setDropdownOpen(!isDropdownOpen)}
              className="text-base hover:text-gray-300"
            >
              How to Book
            </button>
            {/* Dropdown menu */}
            {isDropdownOpen && (
              <ul className="absolute left-0 mt-2 w-40 bg-gray-700 text-white rounded-md shadow-lg z-20">
                <li>
                  <NavLink
                    to="howtobook"
                    className={({ isActive }) =>
                      isActive
                        ? 'text-gray-300 block px-4 py-2'
                        : 'block px-4 py-2 hover:text-gray-300'
                    }
                    onClick={() => {
                      setActivePage('howtobook');
                      setDropdownOpen(false);
                    }}
                  >
                    How To Book
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="ContactUs"
                    className={({ isActive }) =>
                      isActive
                        ? 'text-gray-300 block px-4 py-2'
                        : 'block px-4 py-2 hover:text-gray-300'
                    }
                    onClick={() => {
                      setActivePage('ContactUs');
                      setDropdownOpen(false);
                    }}
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="Pricing"
                    className={({ isActive }) =>
                      isActive
                        ? 'text-gray-300 block px-4 py-2'
                        : 'block px-4 py-2 hover:text-gray-300'
                    }
                    onClick={() => {
                      setActivePage('Pricing');
                      setDropdownOpen(false);
                    }}
                  >
                    Pricing
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          <li>
            <NavLink
              to="/photogallery"
              className={({ isActive }) =>
                isActive ? 'text-gray-300' : 'text-base hover:text-gray-300'
              }
              onClick={() => setActivePage('photogallery')}
            >
              Photo Gallery
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/guestbook"
              className={({ isActive }) =>
                isActive ? 'text-gray-300' : 'text-base hover:text-gray-300'
              }
              onClick={() => setActivePage('guestbook')}
            >
              Guestbook
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/faqs"
              className={({ isActive }) =>
                isActive ? 'text-gray-300' : 'text-base hover:text-gray-300'
              }
              onClick={() => setActivePage('faqs')}
            >
              FAQs
            </NavLink>
          </li>
        </ul>
      </div>

      {/* Hamburger Menu for Mobile */}
      <button
        className="block md:hidden text-white focus:outline-none"
        onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </button>
    </div>
  );
};

export default TopBar;