import React from 'react';

const FAQs: React.FC = () => {
  const faqs = [
    {
      question: "How many people does the villa sleep?",
      answer: "The villa sleeps up to 6 to 8 people. There is 1 double bedroom, 2 twin bedrooms and a sofa bed in the lounge. There is also the option of using some 'put-me-up' beds for small children. Baby cots are provided free of charge.",
    },
    {
      question: "Does the villa have a private pool?",
      answer: "Yes, there is a private pool, which is not overlooked.",
    },
    {
      question: "What is the earliest time we can arrive at the villa & what the is latest we can leave?",
      answer: "The earliest time that you can arrive at the villa is 14:30 (pm) on the day of arrival and the latest that you can leave is 10:30 (am) on the day of departure. Often there can be some flexibility, for example, if the villa is unoccupied immediately before you arrive or after you leave.",
    },
    {
      question: "Is there anything preventing small children inside the villa from being able to get to the swimming pool?",
      answer: "Yes, there are ornate iron gates on all the patio doors, which lead from the villa accommodation to the swimming pool area.",
    },
    {
      question: "Is there a dishwasher at the villa?",
      answer: "Yes, there is a dishwasher, fridge/freezer, washing machine, gas cooker, microwave, toaster, kettle, etc.",
    },
    {
        question: "Are there any shaded areas where you can eat outside?",
        answer: "The villa is south facing for the pool so the sun rises at the back of the villa, where you can at your breakfast, but you can also sit on the pool side of the villa and have your evening meals. There are plenty of shaded areas at the front and back of the villa for eating or relaxing outside.",
      },
      {
        question: "Is smoking permitted?",
        answer: "Smoking is not permitted inside the villa, but it is ok to smoke outside when using an ash tray.",
      },
      {
        question: "Are there any shaded areas where you can eat outside?",
        answer: "The villa is south facing for the pool so the sun rises at the back of the villa, where you can at your breakfast, but you can also sit on the pool side of the villa and have your evening meals. There are plenty of shaded areas at the front and back of the villa for eating or relaxing outside.",
      },
      {
        question: "Is smoking permitted?",
        answer: "Smoking is not permitted inside the villa, but it is ok to smoke outside when using an ash tray.",
      },
      {
        question: "What is in the Welcome Pack when we arrive?",
        answer: "Only the basic essentials to get you started - in case your flight is late and the shops are shut when you arrive. The Welcome Pack normally includes bread, milk, tea bags, coffee, wine, toilet rool, cheese, ham, butter and a bottle of water.",
      },
      {
        question: "Has the villa got air conditioning?",
        answer: "Yes, the villa has air-conditioning in all the bedrooms, kitchen and living room. They are all remotely controllable. Algarve villas with air conditioning are essential especially during peak season. Air conditioning is optional and costs £100 per week.",
      },
      {
        question: "Is the villa suitable for wheelchairs?",
        answer: "Yes, the villa is suitable for wheelchairs as it is on one storey (with the exception of 3 very shallow steeps which lead into the lounge).",
      },
      {
        question: "Do I need to bring towels or linen?",
        answer: "Towels and linen are provided , but please bring your beach towels for using outside.",
      },
      {
        question: "Do I need to bring any electrical convertor plugs if I'm coming from the UK?",
        answer: "Yes. In Portugal they use the 2 pin type, not 3 pin. You can buy these from any boots at the airports.",
      },
      {
        question: "How big is the swimming pool?",
        answer: "The pool is 10 metres long and 5 metres wide.",
      },
      {
        question: "Can the swimming pool be heated?",
        answer: "Yes, the pool can be heated using an electric heat pump. The swimming pool costs £150 per week",
      },
      {
        question: "Does the swimming pool need to be heated?",
        answer: "Not necessarily in high season (June to September), but if there are any small babies that are expected to go into the swimming pool then we would always recommend heating the pool as an change in temperature (regardless of how small), may be enough to put them off from going in - which would be a shame.",
      },
      {
        question: "How long will it take to heat the pool?",
        answer: "Dependent upon the time of year, it can take up to 2 days to heat the pool up (provided the pool blanket is kept on top)",
      },
      {
        question: "Does the pool have a shallow end?",
        answer: "Yes, the pool does have a shallow end with small roman steps down into the shallow end. The shallow end of the pool is 1 metre deep and the deep end is 2 metres deep. There is a blue tiled marker on the base of the pool which shows where the shallow end finishes and the deep starts to get deep. There is plenty of room for small children to play in the shallow end.",
      },
      {
        question: "Do I need to bring anything for the private swimming pool?",
        answer: "Yes. Arm bands for any small children or any lilos. We do leave some out at the beginning of every season, but they unfortunately don't always stand the test of time! Alternatively, you can buy them when you arrive from the market in Guia.",
      },
      {
        question: "What is the nearest airport to Casa Romantica?",
        answer: "The nearest is Faro airport and it is 35 mins drive away from Casa Romantica in Guia.",
      },
      {
        question: "How much does it cost to get get a taxi from Faro airport to the villa?",
        answer: "A taxi normally costs between 30 and 40 Euros to Guia from Faro, but this does depend upon whether you need a normal saloon taxi or a people carrier type car.",
      },
      {
        question: "How long does it take to fly from the UK?",
        answer: "It takes approximately 2 hours 15-45 mins to fly to Faro. Alternatively, you can fly to Lisbon which takes approximately 2 hours and drive to Guia from Lisbon, which takes about 1 hour 30 mins.",
      },
      {
        question: "Is there any time difference between the UK and Portugal?",
        answer: "No. They are always exactly the same.",
      },
      {
        question: "Do I need to get travel insurance?",
        answer: "Yes. Most of of the high street banks or supermarkets can arrange travel insurance for you and your family. There are other deals from time to time - like the Post Office travel policies. It's best to shop around.",
      },
      {
        question: "Where is Casa Romantica located?",
        answer: "Casa Romantica is 30 minutes drive from Faro airport in the Central Algarve, 5 mins walk from the local village of Guia and 5-10 mins drive from the nearest town, which is Albufeira. Albufeira villa holidays are great because of their location in the Central Algarve region of Portugal.",
      },
      {
        question: "Do I need to hire a car if we stay at your villa?",
        answer: "Hiring a car is not essential, you can easily walk to the local shops and restaurants in Guia. There are plenty of reliable local bus services that can take you to the local beaches and restaurants further afield. However, you're probably going to get more out of your holiday if you do hire a car. It is always possible to hire a taxi to/from the villa and nothing is that far away. However, for convenience (particularly in the heat) and flexibility of being able to go a moments notice to out hiring a car would be the better option. It is great way to see the Central Algarve region and the roads are certainly a lot quieter than in the UK!!",
      },
      {
        question: "Where are the nearest mini-markets?",
        answer: "There are 2 mini-markets within a 5 minute walk of the villa.",
      },
      {
        question: "Where is the nearest supermarket?",
        answer: "The nearest supermarket is called Continente and it is in the Central Algarve Shopping Centre, which is just a few minutes drive away. Please note that Continente is open 7 days per week, but it is closed from 21:00 every Sunday.",
      },
      {
        question: "How do we get to the beach from the villa in Guia and Albufeira?",
        answer: "Easy. From the centre of Guia, follow the sign for Vale De Parra. This one, straight road takes you straight to Praia De Gale and the beach. It is easy to drive around the Algarve and there are plenty of taxis also available to take you to and from the beach.",
      },
      {
        question: "Is it possible to hire a taxi from Faro airport as we do not want to drive?",
        answer: "Yes, it is possible to hire a taxi from Faro airport. We will provide you with the taxi details and you can ring the English speaking taxi company and pre-book a taxi for when you arrive at the airport.",
      },
      {
        question: "Are there any local parks for the children to play?",
        answer: "Yes, there are parks and a children’s play ground with swings and slides a short walk away from the villa.",
      },
      {
        question: "How far are the restaurants and bars from the villa?",
        answer: "There are over 20 restaurants in the village of Guia, which are only a 5-10 minute walk away. There are hundreds more in the town of Albufeira, which is 5-10 minutes drive away.",
      },
      {
        question: "Are there any water parks for the children?",
        answer: "Yes, there are several water parks and a Zoomarine only 5 minutes drive from the villa.",
      },
      {
        question: "Where are the nearest cash machines?",
        answer: "There are cash machines located in Guia, only a 5 minute walk away from the villa.",
      },
      {
        question: "Where is the nearest Exchange Bureau?",
        answer: "The nearest exchange bureau is outside the Modelo shopping centre in Albufeira.",
      },
      {
        question: "How far is the villa from the main golf courses in the Algarve?",
        answer: "There are golf courses all along the Algarve coastline, but to give you an idea Guia is approximately 20 mins drive from Vilamoura and 20 minutes drive from Penina -so right in the middle of all the Algarve golf courses!",
      },
      {
        question: "Where is the nearest petrol station from the villa?",
        answer: "There are several GALP petrol stations only a short drive away from the villa - in Guia at Makro and Albufeira.",
      },
      {
        question: "Is there a petrol station on the way back to Faro airport?",
        answer: "There are a few GALP petrol stations on the way back to Faro airport. Most notably the GALP petrol stations. The recommendation is to fill up on your last day on the way back to the airport. Failing that use the GALP petrol station on the main round about outside Faro airport, next to the roundabout with the white statues looking up to the sky.",
      },
      {
        question: "How do we get the keys to the villa?",
        answer: "Once you have paid your final balance an information pack will be sent out to you in the post with all the details enclosed - including where to collect the keys from.",
      },
      {
        question: "Do you have a license to be able to rent the villa out for holidays?",
        answer: "Yes, in accordance with the new Portuguese regulations our villa is licensed for holiday lettings by the Municipality of Albufeira meeting all the relevant safety and comfort standards - CMA License Number 27893/AL.",
      },
      {
        question: "When do I need to do if I want to go ahead and make a booking?",
        answer: "Simply contact the owner, check the dates that you would like are available. If they are, then you will need to complete a brief form, which will be sent to you and then return the form with a 25% deposit to the owner. The full balance it not due then until 10 weeks before you arrive for your holiday. The owner will issue you a receipt for all payments made. For more information please refer to our How to Book section.",
      },
  ];

  return (
    <div className="p-4">
      <h1 className="text-5xl font-bold mt-4 text-center font-serif font-light">Frequently Asked Questions</h1>
      <img src="heart.svg" className="mx-auto mt-0 h-12 w-12" alt="Heart icon" />
      <img src="/images/banner.jpg"/>
      <div className="mt-6 space-y-6">
        {faqs.map((faq, index) => (
          <div key={index} className="border-b border-gray-200 pb-4">
            <h2 className="text-xl font-semibold">{faq.question}</h2>
            <p className="mt-2 text-gray-700">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQs;