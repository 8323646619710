import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopBar from './components/topbar';
import Home from './pages/Home';
import About from './pages/About';
import CalenderPanel from './pages/Calenderpanel';
import ContactUs from './pages/ContactUs';
import HowToBook from './pages/HowToBook';
import Pricing from './pages/pricing';
import PhotoGallery from './pages/photoGallery';
import Guestbook from './pages/Guestbook';
import FAQs from './pages/FAQs';

const App: React.FC = () => {
  const [activePage, setActivePage] = React.useState('home');

  return (
    <Router>
      <div className="h-screen flex flex-col">
        <TopBar setActivePage={setActivePage} />
        {/* Add padding to the top to avoid overlap */}
        <div className="flex-1 pt-16 p-6"> 
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/casa-romantica-algarve-villa.html" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/calendar" element={<CalenderPanel />} />
            <Route path="howtobook" element={<HowToBook />} />
            <Route path="ContactUs" element={<ContactUs />} />
            <Route path="Pricing" element={<Pricing />} />
            <Route path="/photogallery" element={<PhotoGallery />} />
            <Route path="/Guestbook" element={<Guestbook />} />
            <Route path="/FAQs" element={<FAQs />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
