import React from 'react';

const HowToBook: React.FC = () =>  {
  

  return (
    <div>
      <h2 className="text-5xl font-bold mt-4 text-center font-serif font-light">Book your stay</h2>
      <img src="/images/Bookings_image.jpg" alt="Casa Romantica Villa" className="mx-auto my-4" />
      <p className="mt-4"><strong>Step 1</strong>: Call us on <a href="tel:+447733325334" className="text-blue-500 hover:underline">+44 7733 325334</a> to discuss your specific holiday requirements and preferred dates. We will send you an information pack about Casa Romantica either via e-mail and a booking form just in case you decide to go ahead.</p>
      <p className="mt-4">E-mail us at <a href="mailto:enquiries@algarve-villa-holidays.net" className="text-blue-500 hover:underline">enquiries@algarve-villa-holidays.net</a></p>
      <p className="mt-4"><strong>Step 2</strong>: Subject to availability, a verbal option will be held for 5 working days. To secure your booking, we need a completed booking form and deposit of 25% of the total rental cost For further information regarding the cost of the villa please refer to our prices.</p>
      <p className="mt-4"><strong>Step 3</strong>: When we receive your your completed booking form and deposit we will send you a confirmation receipt showing the payment that you have made and the remaining balance. The remaining balance is due 10 weeks before you arrive.</p>
      <p className="mt-4">The final holiday details will also be sent to you once you have made your final balance payment.</p>
      <p className="mt-4">Payments - Bank transfer and International Bank Transfer are accepted.</p>
      <p className="mt-4">Booking Portugal Algarve family villa holidays is very easy when you can deal direct with the villa owner and choose the specific dates that suit you and your family. There are many low cost airlines to choose from that fly to Faro from most UK airports.</p>
      </div>
  );
}
export default HowToBook;