import { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import '../Calendar.css'; // Ensure the correct path to your CSS file

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface Booking {
  start_date: Date;
  end_date: Date;
}

const Calenderpanel: React.FC = () => {
  const [value, onChange] = useState<Value>(new Date());
  const [unavailableDates, setUnavailableDates] = useState<Date[]>([]);

  // Manually define unavailable dates (could also be passed in as props)
  const setUnavailableDatesManually = () => {
    const dates: Date[] = [];
    
    // Example of unavailable dates
    const unavailableBookingData = [
      { start_date: '2025-01-25', end_date: '2025-02-02' },
      { start_date: '2025-02-17', end_date: '2025-03-05' },
      { start_date: '2025-04-08', end_date: '2025-04-14' },
      { start_date: '2025-04-24', end_date: '2025-05-09' },
      { start_date: '2025-06-01', end_date: '2025-06-09' },
      { start_date: '2025-06-26', end_date: '2025-07-05' },
      { start_date: '2025-07-05', end_date: '2025-07-26' },
      { start_date: '2025-08-02', end_date: '2025-08-16' },
      { start_date: '2025-10-19', end_date: '2025-11-01' },
    ];

    // Convert date strings to Date objects and create unavailable dates
    unavailableBookingData.forEach((booking) => {
      const startDate = new Date(booking.start_date);
      const endDate = new Date(booking.end_date);

      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        const dateCopy = new Date(currentDate);
        dateCopy.setHours(0, 0, 0, 0); // Reset time to midnight
        dates.push(dateCopy);
        currentDate.setDate(currentDate.getDate() + 1);
      }
    });

    setUnavailableDates(dates);
  };

  // Add classes to tiles based on availability
  const tileClassName = ({ date }: { date: Date }) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0); // Normalize time

    const isUnavailable = unavailableDates.some(
      (unavailableDate) => unavailableDate.getTime() === normalizedDate.getTime()
    );

    if (isUnavailable) {
      return 'unavailable'; // Apply class for unavailable dates
    }

    return 'available'; // Apply class for available dates
  };

  useEffect(() => {
    setUnavailableDatesManually(); // Set unavailable dates manually
  }, []);

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ];

  return (
    <div className="calendar-container">
      {[...Array(12)].map((_, index) => (
        <div key={index} className="calendar-month">
          <div className="calendar-header">
            <span>{monthNames[index]} 2025</span>
          </div>
          <Calendar
            onChange={onChange}
            value={value}
            tileClassName={tileClassName}
            minDate={new Date(2025, 0, 1)}
            maxDate={new Date(2025, 11, 31)}
            view="month"
            activeStartDate={new Date(2025, index)}
            showNavigation={false}
          />
        </div>
      ))}
    </div>
  );
};

export default Calenderpanel;
