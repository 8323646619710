import React, { useEffect } from 'react';

const Pricing: React.FC = () => {
  // Scroll to top when the page loads or when navigated to
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <div className="p-4">
      <h1 className="text-5xl font-bold mt-4 text-center font-serif font-light">Pricing</h1>
      <img src="heart.svg" className="mx-auto mt-0 h-12 w-12" alt="Heart icon" />
      <h2 className="text-xl font-bold mt-4">Self Catering Villa Algarve Prices</h2>
      <p className="mt-1 text-lg">
        The villa is priced per week for exclusive occupancy, and not per person. Self catering holidays Algarve style
        offers family outdoor living and luxury holiday accommodation at a value-for-money price.
      </p>
      <p className="text-2xl font-bold font-serif font-light mb-4 mt-6">The price includes:</p>
      <ul className="list-disc pl-8 text-gray-800 text-left space-y-2">
        <li>Private exclusive occupancy of Casa Romantica</li>
        <li>Local Guia Villa management and maid service</li>
        <li>All bed linen and towels for inside the villa</li>
        <li>Use of all the villa facilities, including the swimming pool and children's playroom</li>
        <li>All local holiday taxes</li>
        <li>On arrival a welcome pack of groceries and drinks</li>
        <li>Villa utility charges</li>
        <li>All children's high chairs, strollers, cots, and sterilizers are provided free of charge</li>
      </ul>
      <p className="text-2xl font-bold font-serif font-light mb-4 mt-6">The price excludes:</p>
      <ul className="list-disc pl-8 text-gray-800 space-y-2">
        <li>Pool heating, which can be provided at an additional charge of £200 per week</li>
        <li>Air Conditioning, which can be provided at an additional charge of £150 per week</li>
      </ul>

      {/* Pricing Table */}
      <h2 className="text-2xl font-bold mt-8 text-center">2025-2026 Prices</h2>
      <div className="overflow-x-auto mt-6">
        <table className="table-auto w-full border-collapse border border-gray-200">
          <thead>
            <tr className="bg-gray-100 text-left">
              <th className="border border-gray-300 px-4 py-2">Begins</th>
              <th className="border border-gray-300 px-4 py-2">Ends</th>
              <th className="border border-gray-300 px-4 py-2">Weekly</th>
              <th className="border border-gray-300 px-4 py-2">Minimum Stay</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-300 px-4 py-2">14-Dec-24</td>
              <td className="border border-gray-300 px-4 py-2">03-Jan-25</td>
              <td className="border border-gray-300 px-4 py-2">£695</td>
              <td className="border border-gray-300 px-4 py-2">7 nights</td>
            </tr>
            <tr className="bg-gray-50">
              <td className="border border-gray-300 px-4 py-2">04-Jan-25</td>
              <td className="border border-gray-300 px-4 py-2">14-Mar-25</td>
              <td className="border border-gray-300 px-4 py-2">£595</td>
              <td className="border border-gray-300 px-4 py-2">7 nights</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">15-Mar-25</td>
              <td className="border border-gray-300 px-4 py-2">11-Apr-25</td>
              <td className="border border-gray-300 px-4 py-2">£629</td>
              <td className="border border-gray-300 px-4 py-2">7 nights</td>
            </tr>
            <tr className="bg-gray-50">
              <td className="border border-gray-300 px-4 py-2">12-Apr-25</td>
              <td className="border border-gray-300 px-4 py-2">09-May-25</td>
              <td className="border border-gray-300 px-4 py-2">£795</td>
              <td className="border border-gray-300 px-4 py-2">7 nights</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">10-May-25</td>
              <td className="border border-gray-300 px-4 py-2">13-Jun-25</td>
              <td className="border border-gray-300 px-4 py-2">£1,095</td>
              <td className="border border-gray-300 px-4 py-2">7 nights</td>
            </tr>
            <tr className="bg-gray-50">
              <td className="border border-gray-300 px-4 py-2">14-Jun-25</td>
              <td className="border border-gray-300 px-4 py-2">04-Jul-25</td>
              <td className="border border-gray-300 px-4 py-2">£1,395</td>
              <td className="border border-gray-300 px-4 py-2">7 nights</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">05-Jul-25</td>
              <td className="border border-gray-300 px-4 py-2">05-Sep-25</td>
              <td className="border border-gray-300 px-4 py-2">£1,895</td>
              <td className="border border-gray-300 px-4 py-2">7 nights</td>
            </tr>
            <tr className="bg-gray-50">
              <td className="border border-gray-300 px-4 py-2">06-Sep-25</td>
              <td className="border border-gray-300 px-4 py-2">03-Oct-25</td>
              <td className="border border-gray-300 px-4 py-2">£1,295</td>
              <td className="border border-gray-300 px-4 py-2">7 nights</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">04-Oct-25</td>
              <td className="border border-gray-300 px-4 py-2">31-Oct-25</td>
              <td className="border border-gray-300 px-4 py-2">£825</td>
              <td className="border border-gray-300 px-4 py-2">7 nights</td>
            </tr>
            <tr className="bg-gray-50">
              <td className="border border-gray-300 px-4 py-2">01-Nov-25</td>
              <td className="border border-gray-300 px-4 py-2">12-Dec-25</td>
              <td className="border border-gray-300 px-4 py-2">£595</td>
              <td className="border border-gray-300 px-4 py-2">7 nights</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">13-Dec-25</td>
              <td className="border border-gray-300 px-4 py-2">09-Jan-26</td>
              <td className="border border-gray-300 px-4 py-2">£695</td>
              <td className="border border-gray-300 px-4 py-2">7 nights</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Pricing;

